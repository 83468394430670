/* Keyframes for the gradient animation */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Keyframes for the shine effect */
@keyframes shineAnimation {
  0% {
    left: -200%;
  }
  50% {
    left: 150%;
  }
  100% {
    left: 150%;
  }
}

/* Styling for the banner */
.banner {
  position: relative;
  font-family: 'LoveloBlack', sans-serif; /* Using a web font for testing */
  font-weight: bold;
  background: linear-gradient(90deg, rgba(238,219,111,1) 1%, rgba(237,206,97,1) 13%, rgba(251,249,146,1) 25%, rgb(226, 207, 99) 62%, rgba(251,249,146,1) 86%, rgba(216,178,94,1) 100%);
  animation: gradientAnimation 3s ease infinite;
  display: inline-block;
  padding: 0px 1.5rem;
  border-radius: 100px;
  overflow: hidden; /* Ensure the shine effect does not overflow */
}

/* Ensuring the <p> tag inherits the styles */
.banner p {
    color: #a59643; /* Inherit the color from the parent */
    text-align: start;
}

/* Pseudo-element for the shine effect */
.banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: -150%;
  width: 200%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
  animation: shineAnimation 3s ease-in-out infinite;
  animation-delay: 1s; 
  animation-iteration-count: infinite; /* Repeat the animation indefinitely */
  animation-direction: normal;
  transform: skewX(-20deg);
}

@font-face {
    font-family: 'LoveloBlack';
    src: url('../../assets/fonts/LoveloBlack.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  