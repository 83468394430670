.jj-container {
  height: 100vh; /* Make sure the container takes the full height of the viewport */
}

.jj-container .full-container-joinjourney {
  margin-top: -65px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; /* Make sure this container also takes full height */
  width: 100%; 
  position: absolute; /* No need for absolute positioning here */
}

.jj-container .title {
    color: #b96fb1;
    font-family: 'GeneralSans';
    font-size: clamp(20px, 6vw, 40px);
    text-align: center;
    margin: 0;
}

.jj-container .title:hover {
  color: white;
  font-family: 'GeneralSans';
  font-size: clamp(20px, 6vw, 40px);
  text-align: center;
  margin: 0;
}

.jj-container .accept-invite-button {
  background-color: white;
  border-radius: 40px;
  justify-content: center; /* Corrected */
  align-items: center; /* Corrected */
  display: flex;
  text-align: center;
  position: absolute;
  border-color: #b96fb1;
  text-decoration: none;
  padding: 5px 30px; /* Adjust as needed for button padding */
}

.jj-container .accept-invite-button:hover {
  background-color: white; /* Explicitly set background color to white */
  opacity: 0.8; /* Adjust the opacity */
}

.jj-container .accept-invite-button:focus {
  background-color: white;
}

.jj-container .imageScroll {
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.jj-container .path-bg {
  position: absolute;
  bottom: 12%;
  width: 100%;
}

.jj-container .textTop {
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 20%;
  z-index: 10;
}

.jj-container .textTop .title {
  color: white;
  font-family: 'GeneralSans';
  font-size: clamp(20px, 6vw, 40px);
  text-align: center;
  margin: 0;
}
