.countryFrame {
    width: 22%;
    padding-top: 22%;
    margin-left: -11%;
    margin-bottom: -10%;
    position: absolute;
    left: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.25); /* Add shadow directly to the frame */
    background-color: transparent; /* Ensure background is transparent */
}