.featureContentDescription {
  font-family: 'GeneralSans';
  font-size: clamp(14px, 1vw, 23px);
}

.featureContentTitle {
  font-family: 'GeneralSansMedium';
  font-size: clamp(22px, 2vw, 35px);

}

.premium-feature-frame {
  height: 500px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}