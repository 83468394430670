.frontpage .title {
    color:white;
    font-family: 'GeneralSansMedium';
    font-size: 48px;
}

.frontpage .subtitle {
    color:#6e2966;
    margin: 30px 0;
    font-size: 18px;
    font-family: 'GeneralSans';
}

.frontpage {
    margin-top: 100px;
}
.topsection {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
}

.frontpage .title .highlight-text {
    color:black;
    font-size: 48px;
    font-family: 'GeneralSansMedium';
}

.frontpage .downloadLinks {
    flex-direction: row;
    display: flex;
}

.frontpage .downloadLinks .beta-flag {
    position: absolute;
    top: -20px;
    right: -5px;
}

.frontpage .downloadLinks .indev {
    position: absolute;
    top: -8px;
    right: -5px;
    background-color: black;
    padding: 3px 8px;
    border-radius: 20px;
}


.frontpage .downloadLinks .indev-text {
    color: white;
    margin:0;
    font-size: 10px;
    font-family: 'GeneralSans';
}

.frontpage .downloadLinks .store-logo-container {
    padding: 0px 10px;
    position: relative;
}

.frontpage .downloadLinks .store-logo-backdrop {
    box-shadow: 0px 9px 25px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.img-container {
    height: 100%;
    order: 1;
    padding: 20px 10%;
}

.backdrop {
    background-color: white; /* Adjust the background color as needed */
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
}

.img-section {
    justify-content: center;
    align-items: center;
    position: relative;
}
.sc-img-container {
    justify-content: center;
    align-content: center;
}

.sc-img {
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 100%;
    position: relative;
}

.sc-img-sm {
    width: 100%;
    height: 50%;
}

.sc-img-lg {
    width: 100%;
    height: 70%;
}

.sc-img-bg {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 20px;
    width: 100%;
    height: 100%;
    overflow: hidden; /* Ensure content doesn't overflow the container */
    display: flex; /* To ensure the image scales within the div */
    justify-content: center;
    align-items: center;
}

.sc-img-bg svg {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensures the SVG scales proportionally */
}

.first-seperator-frame {
    height: 300px;
    margin-top: -20px;
    padding-right: 50%;
}

.mobile-preview-container {
    display: flex;
    justify-content: center;
}

.subsection-header {
    color: black;
    margin: 5% 0 10% 0;
    font-size: 22px;
    width: 100%;
    justify-content: center;
    display: flex;
    font-family: 'GeneralSansMedium';
}

.mobile-preview {
    justify-content: center;
    align-items: center;
}
.phone-image {
    z-index: 2;
}
.preview-image-container {
    padding-top: 40px;
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
}

.circle-blur {
    position: absolute;
    bottom: -15px;
    z-index: 1;
    width: 100%; /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
    border-radius: 50%;
    background: radial-gradient(circle, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
    filter: blur(10px); /* Adjust blur intensity as needed */
}

.mobile-preview-description-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%; /* Adjust as needed */
    padding: 20px; /* Adjust as needed */
}

.mobile-preview-description-container .title {
    font-size: 50px;
    margin-bottom: 20px;
    color: white;
    font-family: GeneralSansMedium;
    text-align: center;
}

.mobile-preview-description-container .description {
    font-size: 22px;
    color:#6e2966;
    margin: 10px 0;
    font-family: 'GeneralSans';
}

.mobile-preview-description-container .bulletpoints li {
    font-size: 18px;
    color:#6e2966;
    margin: 10px 0;
    font-family: 'GeneralSans';
}

.mobile-preview-description-container .bulletpoints li .bullet-title {
    font-size: 18px;
    color:#6e2966;
    margin: 10px 0;
    font-family: 'GeneralSansMedium';
}


.premium-features-container {
    width: 106vw; /* Ensures the container spans the full viewport width */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -53vw;
    margin-right: -55vw;
    margin-top: 10vw;
}   

.premium-features-container .subcontainer {
    width: 100%; /* Ensures the subcontainer spans the full width of the premium-features-container */
}

.premium-content {
    position: absolute;
    top:30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.premium-content .title-wrapper {
    text-align: center;
    width: 100%; /* Ensures the wrapper takes the full width */
}


.premium-content .title-container {
    padding: 20px;
    display: inline-block;
    position: relative;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 1px solid white;
    box-shadow: 0px 4px 25px rgb(255, 255, 255);
    backdrop-filter: blur(10px); /* Adjust the blur intensity as needed */
    -webkit-backdrop-filter: blur(10px); /* For Safari support */
}

.premium-content .title {
    color:white;
    font-family: 'GeneralSansMedium';
    font-size: clamp(24px, 4vw, 40px);
}

.pro-icon {
    position: absolute;
    width: "100%";
    bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.pro-icon .backshadow {
    position: absolute;
    bottom:0px;
    z-index: 0;
    width: 100px; /* Adjust size as needed */
    height: 15px; /* Adjust size as needed */
    border-radius: 50%;
    border-radius: 5px;
    background: radial-gradient(circle, rgba(54, 51, 26, 0.7) 0%, rgba(0,0,0,0) 100%);
    filter: blur(10px); /* Adjust blur intensity as needed */
}

.plane-seperator {
    margin: -180px 0px -200px 0px;
}

.app-logo-footer {
    width: 50px;
}

@media (max-width: 1400px) {
    .first-seperator-frame {
        height: 200px;
        margin-top: 50px;
}
}

@media (max-width: 1200px) {
    .first-seperator-frame {
        margin-top: 100px;
    }
}

@media (max-width: 992px) {
    .topsection {
        flex-direction: column-reverse; /* Reverse the order of children */
    }

    .mobile-preview-description-container {
        justify-content: flex-start;
        align-items: center;
        margin-top: 50px;
    }
    .first-seperator-frame {
        display: none;
    }

    .mobile-preview-container {
        margin-top: 100px
    }
}

@media (max-width: 768px) {
}




