.about-us-full-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-us-container {
    margin-top: 5rem;
    max-width: 800px;
}

.about-us-container .title {
    color:white;
    font-family: 'GeneralSansMedium';
    font-size: clamp(25px, 7vw, 60px);
    text-align: center;
}

.about-us-container .subtitle {
    color:white;
    font-family: 'GeneralSans';
    font-size: clamp(20px, 5vw, 32px);
    text-align: center;
}

.about-us-body {
  color: #6e2966;
  font-family: 'GeneralSans';
  font-size: clamp(17px, 3vw, 18px);
  text-align: center;
  max-width: 800px;
}

.about-us-body-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.about-us-container .socials-container {
    border-radius: 10px;
    border: 1px solid white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    width: auto;
    margin-top: 5rem;
    position: relative;
}

  
  .social-item {
    margin: 0 10px;
    opacity: 0;
    transform: translateY(50px);
    transition: all 0.5s ease;
  }
  
  .social-item.bounce-in {
    opacity: 1;
    transform: translateY(0);
    animation: bounce 1s ease;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
.follow-us-container {
    background-color: white;
    justify-content: center;
    display: flex;
    width: 50px;
    height: 50px;
    position: absolute;
    top:-18px;
    left:-18px;
    border-radius: 30px;
    word-wrap: break-word;
    align-items: center;
    vertical-align:middle;
    padding-top: 12px;
    box-shadow: 0px 9px 25px rgba(0, 0, 0, 0.15);

}

.follow-us-container .follow-us-text {
    font-size: 10px;
    text-align: center;
    font-family: 'GeneralSansMedium';
    transform: rotate(-10deg);
    color: #b96fb1
}

/* in App.css or about.css */
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

.carousel-container {
  width: 100%;
  height: 200px;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageScroll {
    margin-top: 7rem;
    height: 200px;
    position: relative;
}