/* home.css */

@keyframes slideFadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(-50%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes slideFadeInRight {
    0% {
      opacity: 0;
      transform: translateX(50%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .slide-fade-in-left {
    animation: slideFadeInLeft 1s forwards;
  }
  
  .slide-fade-in-right {
    animation: slideFadeInRight 1s forwards;
  }
  
  .hidden {
    opacity: 0;
    transform: translateX(0);
  }
  

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .fade-in {
    animation: fadeIn 1s forwards;
  }
  