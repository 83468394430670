.App {
  text-align: center;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  background: 
    radial-gradient(circle at left, rgba(96, 53, 143, 0.5), transparent 35%) 0px 1000px,
    radial-gradient(circle at right, rgba(96, 53, 143, 0.5), transparent 40%) 0 500px,
    #B96FB1;
  background-size: 110% 110%;
  background-repeat: repeat-y;
  overflow-x: hidden;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.frontpage .title {
  color:white;
  font-family: 'GeneralSansMedium';
  font-size: 48px;
}

.frontpage .subtitle {
  color:#6e2966;
  margin: 30px 0;
  font-size: 18px;
  font-family: 'GeneralSans';
}
.frontpage .content-paragraph {
  font-size: 18px;
}

.subsection .content-paragraph {
  font-size: 18px;
}

.subsection .title {
  color:#ffffff;
  font-family: 'GeneralSansMedium';
  font-size: 32px;
}

.animated-gradient {
  background: linear-gradient(270deg, #B96FB1, #6F2095);
  animation: gradientAnimation 8s ease infinite;
  width: 100%;
}

.full-container {
  padding: 20px;
}

@font-face {
  font-family: 'GeneralSans';
  src: url('./assets/fonts/GeneralSans-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSansBold';
  src: url('./assets/fonts/GeneralSans-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSansMedium';
  src: url('./assets/fonts/GeneralSans-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Abel';
  src: url('./assets/fonts/Abel-Regular.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LoveloBlack';
  src: url('./assets/fonts/LoveloBlack.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
