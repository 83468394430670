.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);
  -webkit-mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);
}

.marquee-content {
  display: inline-block;
  animation: marquee 20s linear infinite;
}

.marquee-content img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 0 10px;
  object-fit: cover;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.marquee-inner {
  display: flex;
  width: max-content;
}

.marquee-inner > div {
  display: flex;
}
