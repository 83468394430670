.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.App-logo {
    pointer-events: none;
}

.navbar-padding {
    padding: 15px 15px 0 15px
}

.nav-item-custom {
    display: flex;
    position: relative;
    color: white !important;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
}

.brand-logo-title { 
    color:white;
    font-family: 'GeneralSansMedium';
    margin-left: 25px;
    text-decoration: none;
    outline: none;
}

.nav-item-custom::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: white;
    transition: width 0.3s ease, left 0.3s ease;
    transform: translateX(-50%);
  }
  
  .nav-item-custom:hover::after {
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

@media (max-width: 992px) {
  .spacer {
    display: none
  }
}